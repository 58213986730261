<template>
  <div>
    <v-autocomplete
      label="Cod Postal | Población | Provincia"
      no-data-text="No se han encontrado resultados"
      hide-no-data
      hide-selected
      filled
      chips
      small-chips
      :items="items"
      item-value="zico_id"
      :search-input.sync="search"
      :loading="isLoading"
      outlined
      dense
      v-model.lazy="selected.read_idcp"
      no-filter
      autocomplete="new"
      @change="setNewZipId(selected)"
      :rules="[v => requeridoSi(v)]"
      :menu-props="{ closeOnContentClick: true }"
    >
      <template v-slot:selection="data">
        <v-chip
          :input-value="data.selected"
          label
          small
          color="primary"
          @click="data.select"
        >
          {{ data.item.salida }}
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.salida"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";

export default {
  name: "ZipCodesSelector",
  props: ["label", "selected", "requerido", "emitirRegistro"],
  data: () => ({
    isLoading: false,
    search: null,
    entries: [],
    miZipId: null
  }),
  methods: {
    ...mapActions("records", ["setZipCodesSearch"]),
    requeridoSi(val) {
      if (this.requerido) {
        return !val ? "Requerido" : true;
      } else {
        return true;
      }
    },

    busquedaAtrasada(val) {
      if (val === null || val.length < 3) return;
      if (this.isLoading) return;
      this.isLoading = true;
      this.setZipCodesSearch(val)
        .then(res => {
          this.entries = res;
          this.isLoading = false;
          return [];
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() =>
          setTimeout(() => {
            this.isLoading = false;
          }, 300)
        );
    },
    /* setear un nuevo zipcode */
    setNewZipId(tmp) {
      // const salida = !this.miZipId ? null : this.miZipId;
      if (tmp.read_idcp === null) {
        return;
      }

      this.$emit("emitSeleccion", tmp.read_idcp);
    }
  },
  computed: {
    items() {
      try {
        if (!this.entries || this.isLoading) {
          return [];
        }

        return this.entries.map(entry => {
          const salida =
            entry.zico_code +
            " - " +
            entry.zico_town +
            " (" +
            entry.zico_province +
            ")";

          return Object.assign({}, entry, { salida });
        });
      } catch (error) {
        return [];
      }
    }
  },
  watch: {
    search: debounce(function(newVal) {
      this.busquedaAtrasada(newVal);
    }, 200),
    search_(old, val) {
      if (!!val && val.length < 3) return;
      // if (this.items.length > 0) return;
      if (this.codPostalLoading) return;

      this.codPostalLoading = true;

      this.setZipCodesSearch(val)
        .then(res => {
          this.entries = res;
          this.codPostalLoading = false;
          return [];
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() =>
          setTimeout(() => {
            this.codPostalLoading = false;
          }, 300)
        );
    }
  },
  mounted() {
    if (this.selected.read_idcp !== null) {
      this.entries.push(this.selected.zipdata);
    }
  }
};
</script>

<style scoped></style>
